<template>
  <div id="AnnualMeetingList">
    <div class="theme-media">
      <img src="http://freesaasofficedata.oss.aliyuncs.com/rolorweb/20230605145119868.png" />
    </div>
    <div class="box" v-for="(item, index) in arr">
      <yellowTitle v-if="[2, 3].indexOf(item.signupType) >= 0 && item.state == 0" info="审核中（家属）"></yellowTitle>
      <yellowTitle v-if="item.signupType == 1 && item.state == 0" info="审核中"></yellowTitle>
      <yellowTitle v-if="item.signupType == 1 && item.state == 20" info="报名成功"></yellowTitle>
      <yellowTitle v-if="[2, 3].indexOf(item.signupType) >= 0 && item.state == 20" info="报名成功（家属）"></yellowTitle>
      <yellowTitle v-if="[2, 3].indexOf(item.signupType) >= 0 && item.state == -1" :failed="true" info="报名失败（家属）">
      </yellowTitle>
      <yellowTitle v-if="item.signupType == 1 && item.state == -1" :failed="true" info="报名失败"></yellowTitle>
      <div v-if="item.state == 0" class="shenhe_words">资料已经提交成功，需等待客服审核，如有疑问请联系蜜蛋客服</div>
      <div class="line">
        <div class="item_title">姓名：</div>
        <div class="item_content">{{ item.name }}</div>
      </div>
      <div class="line">
        <div class="item_title">性别：</div>
        <div class="item_content">{{ item.sex }}</div>
      </div>
      <div class="line">
        <div class="item_title">民族：</div>
        <div class="item_content">{{ item.minZu }}</div>
      </div>
      <div class="line">
        <div class="item_title">电话：</div>
        <div class="item_content">{{ item.phone }}</div>
      </div>

      <div class="line">
        <div class="item_title">身份证号：</div>
        <div class="item_content">{{ item.idCard }}</div>
      </div>
      <div class="line">
        <div class="item_title">衣服尺码：</div>
        <div class="item_content">{{ item.clothStr }}</div>
      </div>
      <div class="line">
        <div class="item_title">地址：</div>
        <div class="item_content">{{ item.addrStr }}</div>
      </div>
      <div v-if="item.state == -1" class="line">
        <div class="item_title">失败理由：</div>
        <div class="item_content">{{ item.cancelReasons }}</div>
      </div>
    </div>
    <div class="operate">
      <div @click="back" class="continue_btn" v-if="info.isCanSign">继续报名</div>
      <div @click="backHome" class="continue_btn">回到首页</div>
    </div>

  </div>
</template>

<script>
import yellowTitle from "./components/yellowTitle";
export default {
  name: "list",
  components: { yellowTitle },
  data() {
    return {
      windowHeight: '',
      arr: [],
      info: {}
    }
  },
  methods: {
    Init() {
      this.post('/OnlyOnce/AnnualMeeting/GetSignUpList', { source: 3 }, true, 2).then(res => {
        this.arr = res.response || []
      });
      this.post('/OnlyOnce/AnnualMeeting/GetUserDetails', { source: 3 }, true, 2).then(data => {
        if (data.code === 1) {
          this.info = data.response;
        }
      })
    },
    back() {
      if (this.arr.length <= 0) return this.$router.push('/miInformationFill/2')
      if (this.arr.find(item => item.signupType == 1).state == -1) {
        this.$router.push('/miInformationFill/1')
      } else {
        this.$router.push('/miInformationFill/2')
      }
    },
    backHome() {
      this.$router.push("/")
    }
  },
  created() {
    this.windowHeight = window.innerHeight + 'px'
    this.Init()
  },
  mounted() {
    window.scroll({ top: 0 })
  }
}
</script>

<style lang="scss" scoped>
#AnnualMeetingList {
  width: 100%;
  padding-bottom: 30px;
  min-height: 100%;
  box-sizing: border-box;
  background-repeat: no-repeat;
  background-size: 100%;
  font-size: 0;
  background-position: 0px -3px;
  background-color: #1AC1F3;
  overflow: hidden;

  .theme-media {
    img {
      width: 100%;
    }
  }



  .box {
    background-color: #fff;
    margin-left: 20px;
    margin-right: 20px;
    box-sizing: border-box;
    padding: 30px 18px 18px 18px;
    border: 4px solid #209DFF;
    border-radius: 5px;
    margin-top: 30px;
    position: relative;
    font-size: 14px;

    .shenhe_words {
      color: #FF3A3A;
      margin-bottom: 10px;
      margin-left: 25px;
      margin-right: 25px;
    }

    .line {
      display: flex;
      margin-bottom: 10px;

      .item_title {
        color: #999;
        min-width: 100px;
        text-align: start;
      }

      .item_content {
        text-align: start;
        word-break: break-all;
      }
    }
  }

  .operate {
    display: flex;
    padding: 50px 15px 11px;
    align-items: center;

    .continue_btn {
      height: 34px;
      flex: 1;
      background-size: 100%;
      background-repeat: no-repeat;
      color: #fff;
      font-size: 14px;
      line-height: 34px;
      margin-top: 10px;
      border-radius: 34px;
      margin-right: 20px;
      background-color: #209DFF;

      &:last-child {
        color: #209DFF;
        margin-right: 0;
        background-color: #fff;
      }
    }
  }
}
</style>